export const CHALLENGE_TYPE_WHEN_AND_WHERE = 'when_and_where';
export const CHALLENGE_TYPE_HUNT = 'hunt';

export const DEFAULT_DIFFICULTY_MULTIPLIER = {
  'label': '1x: Solvable by most people',
  'code': 1
};

export const DIFFICULTY_MULTIPLIER_OPTIONS = [DEFAULT_DIFFICULTY_MULTIPLIER,{
  'label': '2x: Only a couple clues',
  'code': 2
},{
  'label': '3x: Difficult',
  'code': 3
}];