import {CHALLENGE_TYPE_WHEN_AND_WHERE, CHALLENGE_TYPE_HUNT} from '../constants/PQConstants';

export const challengeToUrl = challenge => {
  if (!challenge) return '';
  if (challenge.challenge_type.type === CHALLENGE_TYPE_WHEN_AND_WHERE) {
    return `/play/when-and-where/${challenge.id}`;
  } else if (challenge.challenge_type.type === CHALLENGE_TYPE_HUNT) {
    return `/play/hunt/${challenge.id}`;
  }

  return '';
}

export const challengeToSolutionUrl = challenge => {
  if (!challenge) return '';
  if (challenge.challenge_type.type === CHALLENGE_TYPE_WHEN_AND_WHERE) {
    return `/play/when-and-where/${challenge.id}/solution`;
  } else if (challenge.challenge_type.type === CHALLENGE_TYPE_HUNT) {
    return `/play/hunt/${challenge.id}/solution`;
  }

  return '';
}

export const challengeToEditUrl = challenge => {
  if (!challenge) return '';
  if (challenge.challenge_type.type === CHALLENGE_TYPE_WHEN_AND_WHERE) {
    return `/me/edit/when-and-where/${challenge.id}`;
  } else if (challenge.challenge_type.type === CHALLENGE_TYPE_HUNT) {
    return `/me/edit/hunt/${challenge.id}`;
  }

  return '';
}