<template>
  <section class="home-main container">
    <h3>Earn points by answering puzzles in user-uploaded images from around the world.</h3>
    <div class="flex desktop-row-mobile-column wrap justify-center">
      <div class="box">
        <div class="title">Play "When and Where"</div>
        <div class="description">Use clues in a photograph to determine where (and for some when) a user submitted photograph was taken.</div>
        <img :src="whenAndWhereImageUrl" />
      </div>
      <div class="box">
        <div class="title">Play "Photo Hunt"</div>
        <div class="description">Search for objects in a user submitted photograph.</div>
        <img :src="photoHuntImageUrl" />
      </div>
      <div class="box">
        <div class="title">Create an Account</div>
        <div class="description">Creating an account allows you to save your score and upload your own challenges for others to play.</div>
        <img :src="createAccountImageUrl" />
      </div>
      <div class="box">
        <div class="title">Create Challenges</div>
        <div class="description">Create your own Photo Hunt or When and Where challenges for your friends and everyone to play.</div>
        <img :src="createChallengesImageUrl" />
      </div>
    </div>
    <div class="call-to-action flex row">
      <nuxt-link to="/play">Play Anonymously Now!</nuxt-link>
      <nuxt-link to="/register">Create an Account</nuxt-link>
    </div>
  </section>
</template>

<script>
  import './HomeMain.scss';
  import GlobalMixin from '~/mixins/GlobalMixin';
  import { imageToSize } from '~/utils/imageUtilFrontend';

  export default {
    props: ['challenges', 'classes'],
    mixins: [GlobalMixin],
    methods: {
      
    },
    computed: {
      whenAndWhereImageUrl() {
        const image = this.$globalSetting('when_and_where_image');

        return imageToSize(image, 'medium');
      },
      photoHuntImageUrl() {
        const image = this.$globalSetting('photo_hunt_image');

        return imageToSize(image, 'medium');
      },
      createAccountImageUrl() {
        const image = this.$globalSetting('create_account_image');

        return imageToSize(image, 'medium');
      },
      createChallengesImageUrl() {
        const image = this.$globalSetting('create_challenges_image');

        return imageToSize(image, 'medium');
      }
    }
  };
</script>
