<template>
  <div :class="`challenge-gallery ${classes}`">
    <div :class="getClassesFor(challenge)" v-for="(challenge,ix) in challenges" v-bind:key="challenge.id">
      <img :src="challengePhotoUrl(challenge)" ref="challengePhoto" :style="photoStyle(ix)" />
    </div>
    <button v-if="moreThanOneChallenge" class="next" @click="nextClickHandler">
      <i class="fas fa-chevron-right"></i>
    </button>
    <button v-if="moreThanOneChallenge" class="prev" @click="prevClickHandler">
      <i class="fas fa-chevron-left"></i>
    </button>

    <div class="overlay flex column" v-if="challenges.length">
      <div class="challenge-type">{{selChallengeType}}</div>
      <div class="by-avatar flex row justify-center center"><avatar :user="selChallengeOwner" /></div>
      <nuxt-link class="play-link transparent" :to="selChallengeUrl">Accept Challenge</nuxt-link>
    </div>
  </div>
</template>

<script>
  import Avatar from './Avatar.vue';
  import './ChallengeGallery.scss';
  import { challengeToUrl } from '~/utils/pqUtil';
  import { imageToSize } from '~/utils/imageUtilFrontend';

  export default {
    components: { Avatar },
    props: ['challenges', 'classes'],
    data() {
      return {
        selIndex: Math.floor(Math.random() * this.challenges.length),
        loadedByIx: []
      }
    },
    mounted() {
      this.interval = setInterval(() => {
        this.nextPhoto();
      }, 6500);

      this.$nextTick(() => {
        this.$refs.challengePhoto.forEach((challengePhotoEl, ix) => {
          this.loadedByIx[ix] = false;

          challengePhotoEl.onload = () => {
            this.loadedByIx[ix] = true;
            this.$forceUpdate();
          };
        });
      })
    },
    computed: {
      moreThanOneChallenge() {
        return this.challenges && this.challenges.length > 1;
      },
      selChallengeType() {
        const challenge = this.challenges[this.selIndex];
        return challenge ? challenge.challenge_type.name : '';
      },
      selChallengeOwner() {
        const challenge = this.challenges[this.selIndex];
        return challenge ? challenge.owner : undefined;
      },
      selChallengeUrl() {
        const challenge = this.challenges[this.selIndex];
        return challenge ? challengeToUrl(challenge) : '';
      }
    },
    methods: {
      photoStyle(ix) {
        return this.loadedByIx[ix] && ix === this.selIndex ? 'display: block' : 'display: none';
      },
      challengePhotoUrl(challenge) {
        return imageToSize(challenge.photos[0], 'original');
      },
      getClassesFor(challenge) {
        const ix = this.challenges.indexOf(challenge);
        if (ix === this.selIndex) {
          return 'challenge selected';
        }

        return 'challenge';
      },
      prevClickHandler() {
        this.clearTransition();
        this.selIndex--;

        if (this.selIndex === -1) {
          this.selIndex = this.challenges.length - 1;
        }
        console.log(this.selIndex);
      },
      nextClickHandler() {
        this.clearTransition();
        this.nextPhoto();
      },
      challengeUrl(challenge) {
        return challengeToUrl(challenge);
      },
      nextPhoto() {
        this.selIndex++;

        if (this.selIndex > this.challenges.length - 1) {
          this.selIndex = 0;
        }
      },
      clearTransition() {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      }
    }
  };
</script>
