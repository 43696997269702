<template>
  <div class="home">
      <challenge-gallery :challenges="challenges" />
      <home-main />
  </div>
</template>

<script>
import './index.scss';
import ChallengeDemos from '~/apollo/queries/ChallengeDemos.gql';
import ChallengeGallery from '~/components/ChallengeGallery';
import HomeMain from '~/components/HomeMain';

export default {
  components: {
    ChallengeGallery,
    HomeMain
  },
  data () {
    return {
      challenges: []
    }
  },
  async asyncData({ app }) {
    const result = await app.apolloProvider.defaultClient.query({ query: ChallengeDemos, variables: { demo: true } });
    if (!result.data.challenges) return { slug, error404: true};
    return { challenges: result.data.challenges, error404: false };
  },
  head () {
    return {
      title:'Photo Quizzer',
      meta: [
        { hid: 'description', name: 'description', content: 'Solve photo riddles from around the world.' }
      ]
    }
  }
}
</script>
